import { PageProps, graphql, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Box } from 'theme-ui';
import ShopFilterProvider from '~/components/Generic/Filter';
import BundleAndSaveSection from '~/components/Generic/Filter/BundleAndSaveSection';
import FilterTagList from '~/components/Generic/Filter/FilterList';
import FilterMenuSlideOut from '~/components/Generic/Filter/FilterMenu';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import ShopAllGrid from '~/components/ShopAll';
import ShopAllHeader from '~/components/ShopAll/Header';
type DataProps = {
  page: {
    specialTitle: string | null;
  };
};
const ShopAllPage = (props: PageProps<DataProps>) => {
  // Get filter props on mount to pass to filter provider
  // don't lowercase
  const filterQuery = new URLSearchParams(props.location.search).get('filter')?.replace('/', '') ?? null;

  // With only 1 color filter at a time need a remove extra colors from existing URLs with as query params
  // Remove all but first color filter
  let firstColorParam = '';
  const formattedQueryParam =
    filterQuery
      ?.split('--')
      .filter((q) => {
        if (q.includes('color-') && !firstColorParam) {
          return (firstColorParam = q);
        } else {
          return !q.includes('color-');
        }
      })
      .join('--') ?? null;

  // update the url to show only 1 color filter
  useEffect(() => {
    if (filterQuery !== formattedQueryParam && formattedQueryParam) {
      const url = new URL(props.location.href);
      url.searchParams.set('filter', formattedQueryParam);
      navigate(url.pathname + url.search, { replace: true });
    }
  }, [formattedQueryParam, filterQuery]);

  const [isOpen, setIsOpen] = useState(false);
  const { specialTitle } = props.data.page;
  return (
    <Layout backgroundColor="white" navColor={true}>
      <Metadata />
      <ShopFilterProvider filterQuery={formattedQueryParam}>
        <ShopAllHeader title={specialTitle}>
          <div>
            <Box
              sx={{
                display: ['none', 'block'],
                px: [0, 'lg'],
                mb: ['2.2rem', '2.8rem'],
              }}
            >
              <BundleAndSaveSection />
            </Box>
            <FilterTagList
              forwardSx={{
                px: '3md',
                pt: '.2rem',
                mb: '3md',
                display: ['flex', 'none'],
              }}
            />
            <FilterMenuSlideOut handleDismiss={() => setIsOpen(false)} initialState={isOpen} />
            <ShopAllGrid />
          </div>
        </ShopAllHeader>
      </ShopFilterProvider>
    </Layout>
  );
};

export default ShopAllPage;

export const pageQuery = graphql`
  query ShopAllPage {
    page: contentfulPage(slug: { eq: "shop-all" }) {
      specialTitle
    }
  }
`;
