import { AnimatePresence, motion } from 'framer-motion';
import { FilteredKeys, useShopFilter } from '..';
import { FilterTagButton } from '../CustomButtons';
import { useState } from 'react';
import BaseButton from '../../BaseButton';
import { Flex, Text, ThemeUIStyleObject } from 'theme-ui';

type FilterTagArgs = {
  label: string;
  property: FilteredKeys;
  slug: string;
};

const MAX_COUNTER = 4;

const FilterTagList = ({
  showRestFilter = true,
  forwardSx,
}: {
  showRestFilter?: boolean;
  forwardSx?: ThemeUIStyleObject;
}) => {
  const {
    state: {
      saved: { filter },
    },
    methods,
  } = useShopFilter();

  const filterTags = Object.keys(filter).reduce<FilterTagArgs[]>((acc, key) => {
    let keyProp = key as FilteredKeys;
    const filterObject = filter[keyProp];
    if (keyProp === 'bundlesOnly' || keyProp === 'gender') return acc; // Skip these

    if (Array.isArray(filterObject)) {
      // Everything else is an array
      const filters = filterObject.map((filter) => ({ label: filter.title, slug: filter.slug, property: keyProp }));
      return [...acc, ...filters];
    }
    return acc;
  }, []);

  const [showRest, setShowRest] = useState(showRestFilter); // Show the rest of the tags in a dropdown

  if (filterTags.length === 0) return null; // If there are no tags, don't render anything

  return (
    <Flex sx={{ alignItems: 'center', gap: '2sm', ...forwardSx }}>
      <motion.div layout sx={{ display: 'flex', gap: '1rem', p: 0, flexWrap: 'wrap' }}>
        <AnimatePresence presenceAffectsLayout>
          {filterTags.map((tag, i) => {
            if (i >= MAX_COUNTER && !showRest) return null;

            return (
              <motion.div
                layout
                key={`${tag.slug}-${tag.property}`}
                initial={{ opacity: 0, x: 10 }}
                animate={{
                  opacity: 1,
                  x: 0,
                }}
                exit={{
                  transition: {
                    opacity: { duration: 0.2 },
                  },
                  opacity: 0,
                  y: 10,
                }}
              >
                <FilterTagButton
                  {...tag}
                  handleClick={() => {
                    methods.remove({ property: tag.property, slug: tag.slug });
                    if (tag.property === 'colors') methods.remove({ property: 'excludeBundles' });
                    methods.save();
                  }}
                />
              </motion.div>
            );
          })}
        </AnimatePresence>
      </motion.div>
      {!showRest && filterTags.length > MAX_COUNTER && (
        <BaseButton
          sx={{
            height: '2rem',
            p: 0,
            background: 'none',
            textDecoration: 'underline',
            textUnderlineOffset: '0.3rem',
          }}
        >
          <Text
            variant="button"
            onClick={() => {
              setShowRest(true);
            }}
          >
            + {filterTags.length - MAX_COUNTER}
          </Text>
        </BaseButton>
      )}
    </Flex>
  );
};

export default FilterTagList;
