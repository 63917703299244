import React, { ReactNode } from 'react';
import { Box, Flex, Text } from 'theme-ui';
import { FilterPageToggle } from '../Generic/Filter/FilterPageButton';
import FilterBar from '../Generic/Filter/FilterBar';
import useNavigationHeight from '~/context/NavigationContext/useNavigationHeight';

const ShopAllHeader = ({ children, title }: { children: ReactNode; title: string | null }) => {
  const { navHeight } = useNavigationHeight();
  const topValue = `${navHeight ?? 70 - 1}px`;
  return (
    <div sx={{ backgroundColor: 'white', pt: [0, 'xxl'] }}>
      {/* Shop all heading */}
      <Flex
        sx={{
          display: ['none', 'flex'],
          mx: 'auto',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          gap: '3md',
          mb: ['2md', '2sm'],
        }}
      >
        <Text
          variant="h5"
          sx={{
            fontWeight: '500',
            textAlign: 'center',
            fontSize: ['3.6rem', '2.8rem'],
            px: ['2.4rem', '0'],
          }}
        >
          {title ?? 'Shop All'}
        </Text>
        <FilterPageToggle />
      </Flex>

      <Box
        sx={{
          mb: ['3rem', '3md'],
          position: 'sticky',
          zIndex: 2,
          isolation: 'isolate',
        }}
        style={{ top: topValue }}
      >
        <FilterBar />
      </Box>
      {children}
    </div>
  );
};

export default ShopAllHeader;
