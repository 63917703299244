import { graphql, useStaticQuery } from 'gatsby';
import { mapBundleFilterData, mapKitFilterData, mapProductFilterData } from '~/components/Generic/Filter/filterHelpers';
import { TransformedBundle, TransformedKit, TransformedProduct, TransformedBundleOrProductOrKit } from '~/types/filter';

const useProductsAndKitsWithFilterQuery = () => {
  const data = useStaticQuery(graphql`
    query AllKitsAndProductsFilter {
      productsWithValue: allContentfulProduct(
        filter: {
          collection: { elemMatch: { catalog: { elemMatch: { slug: { eq: "catalog" } } } } }
          customValue: { ne: null }
          hideOnShopAll: { ne: true }
        }
        sort: { fields: customValue, order: DESC }
      ) {
        edges {
          node {
            ...ProductCardFragment
          }
        }
      }
      productsWithoutValue: allContentfulProduct(
        filter: {
          collection: { elemMatch: { catalog: { elemMatch: { slug: { eq: "catalog" } } } } }
          customValue: { eq: null }
          hideOnShopAll: { ne: true }
        }
      ) {
        edges {
          node {
            ...ProductCardFragment
          }
        }
      }
      kitsWithValue: allContentfulKit(
        filter: {
          collection: { elemMatch: { catalog: { elemMatch: { slug: { eq: "catalog" } } } } }
          customValue: { ne: null }
          hideOnShopAll: { ne: true }
        }
        sort: { fields: customValue, order: DESC }
      ) {
        edges {
          node {
            ...KitCardFragment
          }
        }
      }
      kitsWithoutValue: allContentfulKit(
        filter: {
          collection: { elemMatch: { catalog: { elemMatch: { slug: { eq: "catalog" } } } } }
          customValue: { eq: null }
          hideOnShopAll: { ne: true }
        }
      ) {
        edges {
          node {
            ...KitCardFragment
          }
        }
      }
      bundlesWithValue: allContentfulBundle(
        filter: {
          collection: { elemMatch: { catalog: { elemMatch: { slug: { eq: "catalog" } } } } }
          customValue: { ne: null }
          hideOnShopAll: { ne: true }
        }
        sort: { fields: customValue, order: DESC }
      ) {
        edges {
          node {
            ...BundleCard
          }
        }
      }
      bundlesWithoutValue: allContentfulBundle(
        filter: {
          collection: { elemMatch: { catalog: { elemMatch: { slug: { eq: "catalog" } } } } }
          customValue: { eq: null }
          hideOnShopAll: { ne: true }
        }
      ) {
        edges {
          node {
            ...BundleCard
          }
        }
      }
    }
  `);

  const productsWithValue: TransformedProduct[] =
    data.productsWithValue?.edges.map(({ node }: { node: Queries.ProductCardFragmentFragment }) => {
      const transformedProduct: TransformedProduct = { ...node, filterData: mapProductFilterData(node) };
      return transformedProduct;
    }) ?? [];
  const productsWithoutValue: TransformedProduct[] =
    data.productsWithoutValue?.edges.map(({ node }: { node: Queries.ProductCardFragmentFragment }) => {
      const transformedProduct: TransformedProduct = {
        ...node,
        customValue: 0,
        filterData: mapProductFilterData(node),
      };
      return transformedProduct;
    }) ?? [];

  const products: TransformedProduct[] = [...productsWithValue, ...productsWithoutValue];

  const kitsWithValue: TransformedKit[] =
    data.kitsWithValue?.edges.map(({ node }: { node: Queries.KitCardFragmentFragment }) => {
      const transformedKit: TransformedKit = { ...node, filterData: mapKitFilterData(node) };
      return transformedKit;
    }) ?? [];
  const kitsWithoutValue: TransformedKit[] =
    data.kitsWithoutValue?.edges.map(({ node }: { node: Queries.KitCardFragmentFragment }) => {
      const transformedKit: TransformedKit = { ...node, customValue: 0, filterData: mapKitFilterData(node) };
      return transformedKit;
    }) ?? [];

  const kits: TransformedKit[] = [...kitsWithValue, ...kitsWithoutValue];

  const bundlesWithValue: TransformedBundle[] =
    data.bundlesWithValue?.edges.map(({ node }: { node: Queries.BundleCardFragment }) => {
      const transformedBundle: TransformedBundle = { ...node, filterData: mapBundleFilterData(node) };
      return transformedBundle;
    }) ?? [];
  const bundlesWithoutValue: TransformedBundle[] =
    data.bundlesWithoutValue?.edges.map(({ node }: { node: Queries.BundleCardFragment }) => {
      const transformedBundle: TransformedBundle = { ...node, customValue: 0, filterData: mapBundleFilterData(node) };
      return transformedBundle;
    }) ?? [];

  const bundles: TransformedBundle[] = [...bundlesWithValue, ...bundlesWithoutValue];

  // This is more efficient than sorting the arrays because they are already sorted
  function mergeSortedArrays<T>(arr1: T[], arr2: T[], arr3: T[], property: keyof T) {
    const mergedArray = [];
    let i = 0;
    let j = 0;
    let k = 0;

    while (i < arr1.length && j < arr2.length && k < arr3.length) {
      if (arr1[i][property] <= arr2[j][property] && arr1[i][property] <= arr3[k][property]) {
        mergedArray.push(arr1[i]);
        i++;
      } else if (arr2[j][property] <= arr1[i][property] && arr2[j][property] <= arr3[k][property]) {
        mergedArray.push(arr2[j]);
        j++;
      } else {
        mergedArray.push(arr3[k]);
        k++;
      }
    }

    // Push remaining elements from arr1, arr2, and arr3 (if any)
    while (i < arr1.length) {
      mergedArray.push(arr1[i]);
      i++;
    }

    while (j < arr2.length) {
      mergedArray.push(arr2[j]);
      j++;
    }

    while (k < arr3.length) {
      mergedArray.push(arr3[k]);
      k++;
    }

    return mergedArray;
  }

  return mergeSortedArrays<TransformedBundleOrProductOrKit>(products, kits, bundles, 'customValue');
};

export default useProductsAndKitsWithFilterQuery;
