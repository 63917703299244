import { forwardRef } from 'react';
import { Label, LabelProps, Radio, RadioProps, Text } from 'theme-ui';

export interface RadioButtonProps {
  radioProps: RadioProps;
  labelProps?: Omit<LabelProps, 'children'>;
  labelString: string;
}

const RadioButton = forwardRef<HTMLInputElement, RadioButtonProps>(function RadioButton(props, ref) {
  return (
    <Label
      {...(props.labelProps ?? {})}
      sx={{
        cursor: 'pointer',
        alignItems: 'center',
      }}
    >
      <Radio
        ref={ref}
        sx={{
          size: '2.2rem',
          ml: '-0.2rem',
        }}
        {...props.radioProps}
      />
      <Text variant="label">{props.labelString}</Text>
    </Label>
  );
});

export default RadioButton;
