export type FilterObject<T = string> = {
  slug: T;
  title: string;
};

export type FilterObjectWithAlternate = FilterObject & {
  alternateRendering: string;
};

export type SortObject = {
  title: string;
  value: SortKeys;
};

export enum SortKeys {
  PRICE = 'price',
  WEIGHT = 'weight',
  CUSTOM = 'custom',
}

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export type SortType = {
  by: SortObject;
  order: SortDirection;
};

// Transformed product/kits types
export type TransformedSortData = {
  price: number;
  ounceWeight: number;
  customValue: number;
};

export type TransformedFilterData = {
  colors: string[];
  sizes: string[];
  collections: string[];
  gender: string[];
  isBundle: boolean;
};

export type TransformedFilterSortData = TransformedFilterData & TransformedSortData;

export type TransformedProduct = {
  filterData: TransformedFilterSortData;
} & Queries.ProductCardFragmentFragment;

export type TransformedKit = {
  filterData: TransformedFilterSortData;
} & Queries.KitCardFragmentFragment;

export type TransformedBundle = {
  filterData: TransformedFilterSortData;
} & Queries.BundleCardFragment;

export type TransformedProductOrKit = TransformedProduct | TransformedKit;

export type TransformedBundleOrProductOrKit = TransformedProduct | TransformedKit | TransformedBundle;
