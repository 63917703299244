import { Text } from 'theme-ui';
import CTAButton from '../../CTAButton';

type SizeSelectorProps = {
  isSelected: boolean;
  title: string;
};

const SizeSelector = ({ isSelected, title }: SizeSelectorProps) => {
  return (
    <CTAButton
      variant="Outline"
      baseColor="black"
      as={'div'}
      type="button"
      forwardSx={{
        borderRadius: '2xs',
        border: '1px solid',
        borderColor: isSelected ? 'black' : 'cloud',
        px: '1.5rem',
        py: '2xs',
        backgroundColor: isSelected ? 'black' : 'transparent',
        color: isSelected ? 'cloud' : 'black',
      }}
    >
      <Text variant="buttonLight">{title}</Text>
    </CTAButton>
  );
};

export default SizeSelector;
