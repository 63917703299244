import { graphql, useStaticQuery } from 'gatsby';
import { transformToFilterObject } from '~/components/Generic/Filter/filterHelpers';
import { FilterObject, FilterObjectWithAlternate } from '~/types/filter';

type ReturnedData = {
  colorOptionValues: FilterObjectWithAlternate[];
  sizeOptionValues: FilterObject[];
  categoryCollectionFilter: FilterObject[];
};

// Filter out options that are hidden from the filter
const getStandardOptions = (options: Queries.OptionValuesFragmentFragment[]) =>
  options?.filter((option) => !option.hideFromFilter) ?? [];

const useAllFilterOptionsQuery = (): ReturnedData => {
  const data = useStaticQuery(graphql`
    query AllFilterOptions {
      # Query all option values that are standard size
      allContentfulOptionType {
        edges {
          node {
            ...OptionTypeFragment
            optionValues {
              ...OptionValuesFragment
            }
          }
        }
      }

      catalog: allContentfulCatalog(filter: { slug: { eq: "catalog" } }) {
        edges {
          node {
            categoryCollectionFilter {
              ...DataPointFragment
            }
          }
        }
      }
    }
  `);

  const colorOptionValues: Queries.OptionValuesFragmentFragment[] =
    getStandardOptions(
      data.allContentfulOptionType.edges.find(
        ({ node }: { node: Queries.ContentfulOptionType }) => node.slug === 'color'
      )?.node.optionValues
    ) ?? [];

  const sizeOptionValues: Queries.OptionValuesFragmentFragment[] =
    getStandardOptions(
      data.allContentfulOptionType.edges.find(
        ({ node }: { node: Queries.ContentfulOptionType }) => node.slug === 'size'
      )?.node.optionValues
    ) ?? [];

  const categoryCollectionFilter: Queries.DataPointFragmentFragment[] =
    data.catalog.edges[0].node.categoryCollectionFilter ?? [];

  return {
    colorOptionValues: colorOptionValues.map((colorOption) =>
      transformToFilterObject(colorOption)
    ) as FilterObjectWithAlternate[],
    sizeOptionValues: sizeOptionValues.reduce((acc, value) => {
      // prevent duplicates

      if (acc.find((item: FilterObject) => item.slug === value.presentation)) return acc;
      return [...acc, transformToFilterObject({ ...value, slug: value.presentation })];
    }, [] as FilterObject[]),
    categoryCollectionFilter: categoryCollectionFilter.map((categoryCollection) => {
      return transformToFilterObject(categoryCollection);
    }),
  };
};

export default useAllFilterOptionsQuery;
