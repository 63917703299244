import { useShopFilter } from '..';
import AnimatedSlidingToggle from '../../AnimatedSlidingToggle';

const genderOptionsForTab = [
  { value: 'all', label: 'All' },
  { value: 'womens', label: 'Womens' },
  { value: 'mens', label: 'Mens' },
];

const FilterPageToggle = () => {
  const {
    methods,
    state: {
      saved: { filter },
      availableFilterOptions,
    },
  } = useShopFilter();

  const index = genderOptionsForTab.findIndex((tab) => tab.value === filter.gender.slug);

  return (
    <AnimatedSlidingToggle
      tabs={genderOptionsForTab}
      currentActiveButtonIndex={index === -1 ? 0 : index}
      handleClick={(slug) => {
        const genderFilterArray = availableFilterOptions.gender;
        const currentSelectedGender = genderFilterArray.find((option) => option.slug === slug);
        if (!currentSelectedGender) return;

        methods.filter({ property: 'gender', payload: currentSelectedGender });
        methods.save();
      }}
    />
  );
};

export { FilterPageToggle };
