import { DialogContent, DialogOverlay } from '@reach/dialog';
import { useEffect, useRef, useState } from 'react';
import FilterMenu from './FilterMenu';
import { useShopFilter } from '..';
import useOnClickOutside from '~/hooks/components/use-on-click-outside';
import { OpenModal, OpenModalParams } from '~/types/customEvents';

const ID = 'filterMenu';

// custom event fire to open the modal
export const openModal = () => {
  if (typeof window === 'undefined') return;
  const openModalEvent = new CustomEvent<OpenModalParams>('openModal', {
    detail: {
      modalId: ID,
    },
  });
  window.dispatchEvent(openModalEvent);
};

const FilterMenuSlideOut = ({ initialState, handleDismiss }: { initialState: boolean; handleDismiss?: () => void }) => {
  const [isVisible, setIsVisible] = useState(initialState);
  const [isOpen, setIsOpen] = useState(initialState);

  const { methods } = useShopFilter();

  const timerRef = useRef<NodeJS.Timeout>();

  const dismissModal = () => {
    setIsVisible(false);
    methods.loadPrevious();
    handleDismiss && handleDismiss();
    timerRef.current = setTimeout(() => setIsOpen(false), 300);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const customModalEvent = (event: OpenModal) => {
      if (event.detail.modalId !== ID) return;
      setIsOpen(true);
      timer = setTimeout(() => setIsVisible(true), 100);
    };
    // listen for openModal event
    window.addEventListener('openModal', customModalEvent as EventListener);
    return () => {
      window.removeEventListener('openModal', customModalEvent as EventListener);
      clearTimeout(timerRef.current);
      clearTimeout(timer);
    };
  }, []);

  const containerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(containerRef, dismissModal);

  // add controlledIsOpen so it can be controlled by parent component

  return (
    <DialogOverlay
      id={ID}
      isOpen={isOpen}
      onDismiss={dismissModal}
      sx={{
        zIndex: 999,
        top: 0,
        position: 'fixed',
        inset: 0,
        overflow: 'clip',
        display: 'flex',
        height: '100%',
        justifyContent: 'end',
        alignItems: 'center',
        background: isVisible ? 'rgb(0,0,0,0.5)' : 'rgb(0,0,0,0)',
        transition: 'background-color 0.3s ease-in-out',
      }}
    >
      <DialogContent
        ref={containerRef}
        aria-label="Filter Menu"
        sx={{
          '--transition-duration': '0.4s',
          '--reveal-transition': 'all var(--transition-duration) ease-in-out',
          transition: 'var(--reveal-transition)',
          right: isVisible ? '0%' : '-100%',
          p: 0,
          m: 0,
          position: 'fixed',
          zIndex: 999,
          background: 'white',
          height: '100%',
          top: 0,
        }}
      >
        <FilterMenu {...{ handleDismiss: dismissModal }} />
      </DialogContent>
    </DialogOverlay>
  );
};

export default FilterMenuSlideOut;
