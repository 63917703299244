import { Box, Button, Flex, IconButton, Text } from 'theme-ui';
import { useShopFilter } from '..';
import CloseV2 from '~/assets/images/icons/closeV2.svg';
import StaticFilterSection from './StaticFilterSection';

import CTAButton from '../../CTAButton';
import { CheckboxButton, CustomInputButton, RadioButton } from '../../GenericInputs';
import { ColorSelector, SizeSelector } from '../CustomButtons';
import { useForm } from 'react-hook-form';
import { ChangeEvent, useState, useMemo } from 'react';
import { SortDirection, SortKeys, SortType } from '~/types/filter';
import DetailSection from './DetailSection';
import { ColorCurrentlySelected, TextCurrentlySelected } from './DetailSection/Components';
import BaseButton from '../../BaseButton';

type FilterMenuProps = {
  handleDismiss: () => void;
};

type FormState = {
  sizes: string[];
  colors: string[];
  gender: string;
  collection: string[];
  sortBy: string;
  bundlesOnly: boolean;
  excludeBundles: boolean;
};

const FilterMenu = ({ handleDismiss }: FilterMenuProps) => {
  const {
    methods,
    state: { current, availableFilterOptions, availableSortOptions, dirty },
  } = useShopFilter();

  // Changing the key on the form closes all collapsible panels
  const [clearKey, setClearKey] = useState<number>(0);

  // initial form state where each property is a key from the SelectedFilterOptions type

  const filter = current.filter;
  const sort = current.sort;
  const uncommittedProductChange = current.filteredProducts;

  const conditionalSortBy = (sort: SortType) => {
    /**
     * Because the custom sort doesnt have asc/desc visual sort state, we
     * need to check if sort is custom. If it is, just use the sort.by object
     * to determine the value. Otherwise, stringify the entire sort object
     */
    if (sort.by.value === SortKeys.CUSTOM) return JSON.stringify(sort.by);
    return JSON.stringify(sort);
  };

  const initialFormState: FormState = useMemo(
    () => ({
      sizes: filter.sizes.map((size) => JSON.stringify(size)),
      colors: filter.colors.map((color) => JSON.stringify(color)),
      gender: JSON.stringify(filter.gender),
      collection: filter.collection.map((object) => JSON.stringify(object)),
      sortBy: conditionalSortBy(sort),
      bundlesOnly: filter.bundlesOnly,
      excludeBundles: filter.excludeBundles,
    }),
    [filter, sort]
  );

  const { register, handleSubmit, watch, reset } = useForm<FormState>({
    values: initialFormState,
  });

  const watchSizes = watch('sizes');
  const watchColors = watch('colors');

  const onSubmit = () => {
    methods.save();
    handleDismiss();
  };

  const getSortPresentation = (sortObject: SortType) => {
    const order = sortObject.order;
    const value = sortObject.by.value;
    const title = sortObject.by.title;
    if (value === SortKeys.CUSTOM) return sortObject.by.title;
    const orderPresentation = order === SortDirection.ASC ? 'Low to high' : 'High to low';
    return `${title}: ${orderPresentation}`;
  };

  const productsFoundLength = uncommittedProductChange.length;

  const clearForm = () => {
    methods.clear();
    reset();
    // Change key from one state to another on clear to rerender form
    setClearKey((state) => (state === 0 ? 1 : 0));
    handleDismiss();
  };
  return (
    <Flex
      key={`filterMenu-${clearKey}`}
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        flexDirection: 'column',
        width: ['calc(100vw - 6rem)', '48.3rem'],
        position: 'relative',
        zIndex: 999,
        background: 'white',
        height: '100vh',
        '@supports (height: 100dvh)': {
          height: '100dvh',
        },
        top: 0,
        right: 0,
      }}
    >
      {/* Controls */}
      <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', pt: '2md', px: '2md', position: 'relative' }}>
        <IconButton
          type="button"
          onClick={handleDismiss}
          sx={{
            p: 0,

            width: 'fit-content',
            height: 'fit-content',
            px: 'sm',
            py: 'xs',
            ml: '-1.6rem',
            cursor: 'pointer',
            color: 'black',
          }}
        >
          <CloseV2 />
        </IconButton>

        <BaseButton
          type="button"
          onClick={clearForm}
          sx={{
            color: dirty ? 'black' : 'UIGray1',
            position: 'relative',
            // disable border when not hovering
            border: 'none',
            backgroundColor: 'transparent',

            '&:hover': {
              backgroundColor: 'transparent',
              border: 'none',
              color: 'black',
            },
            mr: '-1.6rem',
            px: 'sm',
            height: 'fit-content',
            borderRadius: 'xs',
            isolation: 'isolate',
          }}
        >
          <Text variant="button">Clear All</Text>
        </BaseButton>
      </Flex>
      {/* Title */}
      <Box sx={{ px: '2md', position: 'relative' }}>
        <StaticFilterSection forwardSx={{ pb: ['3rem', 'md'], py: ['md', '3rem'] }}>
          <Text
            variant="h6"
            sx={{
              fontWeight: '500',
            }}
          >
            Filter & Sorting
          </Text>
        </StaticFilterSection>
      </Box>
      {/* Filter */}
      <Box sx={{ position: 'relative', overflow: 'hidden', height: '100%' }}>
        <Box
          sx={{
            px: '2md',
            maxHeight: '100%',
            overflowX: 'hidden',
            overflowY: 'auto',
            position: 'relative',
            pb: '2md',
          }}
        >
          <DetailSection
            {...{ title: 'Sort By', currentValue: sort.by.title }}
            CurrentSelectedRenderComponent={<TextCurrentlySelected currentValue={getSortPresentation(sort)} />}
          >
            <Flex sx={{ flexDirection: 'column', gap: 'sm' }}>
              {availableSortOptions.map((option) => {
                const orderPresentation = getSortPresentation(option);

                return (
                  <RadioButton
                    key={`${option.by.value}-${option.order}`}
                    {...register('sortBy', { required: true })}
                    radioProps={{
                      name: 'sortBy',
                      defaultValue: conditionalSortBy(option),
                      onChange: () => {
                        methods.sort(option);
                      },
                    }}
                    labelString={orderPresentation}
                  />
                );
              })}
            </Flex>
          </DetailSection>
          <DetailSection
            {...{ title: 'Gender' }}
            CurrentSelectedRenderComponent={<TextCurrentlySelected currentValue={filter.gender.title} />}
          >
            <Flex sx={{ flexDirection: 'column', gap: 'sm' }}>
              {availableFilterOptions.gender.map((gender) => (
                <RadioButton
                  key={gender.slug}
                  {...register('gender', { required: true })}
                  radioProps={{
                    name: 'gender',
                    value: JSON.stringify(gender),
                    onChange: (event) => {
                      event.target.checked
                        ? methods.filter({ property: 'gender', payload: gender })
                        : methods.remove({ property: 'gender', slug: gender.slug });
                    },
                  }}
                  labelString={gender.title}
                />
              ))}
            </Flex>
          </DetailSection>
          <DetailSection
            {...{ title: 'Collection', showSelectionOnOpen: false }}
            CurrentSelectedRenderComponent={
              <TextCurrentlySelected
                currentValue={filter.collection.reduce((acc, value, i) => {
                  return acc + value.title + (i === filter.collection.length - 1 ? '' : ', ');
                }, '')}
              />
            }
          >
            <Flex sx={{ flexDirection: 'column', gap: 'sm' }}>
              {availableFilterOptions.collection.map((option) => {
                return (
                  <CheckboxButton
                    key={option.slug}
                    checkboxProps={{
                      name: 'collection',
                      value: JSON.stringify(option),
                      onChange: (event) => {
                        event.target.checked
                          ? methods.filter({ property: 'collection', payload: option })
                          : methods.remove({ property: 'collection', slug: option.slug });
                      },
                    }}
                    labelString={option.title}
                    {...register('collection')}
                  />
                );
              })}
            </Flex>
          </DetailSection>
          <DetailSection
            {...{ title: 'Sizes', showSelectionOnOpen: false }}
            CurrentSelectedRenderComponent={
              <TextCurrentlySelected
                currentValue={filter.sizes.reduce((acc, value, i) => {
                  return acc + value.title + (i === filter.sizes.length - 1 ? '' : ', ');
                }, '')}
              />
            }
          >
            <Flex sx={{ flexDirection: 'row', gap: 'sm', flexWrap: 'wrap' }}>
              {availableFilterOptions.sizes.map((option) => {
                return (
                  <CustomInputButton
                    key={option.slug}
                    {...register('sizes')}
                    type="checkbox"
                    render={() => {
                      return (
                        <SizeSelector
                          isSelected={watchSizes.some((size) => size === JSON.stringify(option))}
                          title={option.title}
                        />
                      );
                    }}
                    inputProps={{
                      name: 'sizes',
                      value: JSON.stringify(option),
                      onChange: (event) => {
                        event.target.checked
                          ? methods.filter({ property: 'sizes', payload: option })
                          : methods.remove({ property: 'sizes', slug: option.slug });
                      },
                    }}
                  />
                );
              })}
            </Flex>
          </DetailSection>
          <DetailSection
            {...{ title: 'Colors', showSelectionOnOpen: false }}
            CurrentSelectedRenderComponent={
              <Flex sx={{ gap: '2xs', flexWrap: 'wrap' }}>
                {filter.colors.map((color) => {
                  return <ColorCurrentlySelected key={color.slug} currentValue={color.alternateRendering} />;
                })}
              </Flex>
            }
          >
            <Flex sx={{ flexDirection: 'row', gap: 'sm', flexWrap: 'wrap' }}>
              {availableFilterOptions.colors.map((option) => {
                return (
                  <CustomInputButton
                    key={option.slug}
                    type="checkbox"
                    {...register('colors')}
                    render={() => {
                      return (
                        <ColorSelector
                          color={option.alternateRendering}
                          isSelected={watchColors.some((color) => color === JSON.stringify(option))}
                          title={option.title}
                        />
                      );
                    }}
                    inputProps={{
                      name: 'colors',
                      value: JSON.stringify(option),
                      onChange: (event) => {
                        if (event.target.checked) {
                          methods.filter({ property: 'colors', payload: option });
                          methods.filter({ property: 'excludeBundles', payload: event.target.checked });
                        } else {
                          methods.remove({ property: 'colors', slug: option.slug });
                          methods.remove({ property: 'excludeBundles' });
                        }
                      },
                    }}
                  />
                );
              })}
            </Flex>
          </DetailSection>
          <StaticFilterSection>
            <CheckboxButton
              {...register('bundlesOnly')}
              {...{
                labelString: 'Only Show Bundles',
                checkboxProps: {
                  value: JSON.stringify(filter.bundlesOnly),
                  name: 'bundlesOnly',
                  onChange: (event: ChangeEvent<HTMLInputElement>) => {
                    methods.filter({ property: 'bundlesOnly', payload: event.target.checked });
                    if (filter.excludeBundles) methods.remove({ property: 'excludeBundles' });
                  },
                },
                variant: 'right',
              }}
            />
          </StaticFilterSection>
          <StaticFilterSection>
            <CheckboxButton
              {...register('excludeBundles')}
              {...{
                labelString: 'Exclude Bundles',
                checkboxProps: {
                  value: JSON.stringify(filter.bundlesOnly),
                  name: 'excludeBundles',
                  onChange: (event: ChangeEvent<HTMLInputElement>) => {
                    methods.filter({ property: 'excludeBundles', payload: event.target.checked });
                    if (filter.bundlesOnly) methods.remove({ property: 'bundlesOnly' });
                  },
                },
                variant: 'right',
              }}
            />
          </StaticFilterSection>
        </Box>
        {/* Button */}
      </Box>
      <Box
        sx={{
          p: '3md',
          borderTop: '1px solid',
          borderColor: 'cardGrey',
          height: 'fit-content',
          position: 'relative',
          bottom: 0,
          width: '100%',
        }}
      >
        <CTAButton
          type="submit"
          disabled={productsFoundLength === 0}
          variant="Filled"
          baseColor="Black"
          sx={{
            width: '100%',
            ':disabled': {
              color: 'UIGray2',
              background: 'transparent',
              border: '1px solid cardGrey',
            },
          }}
        >
          <Text variant="button">
            {productsFoundLength === 0 ? 'No matching products' : `Show Products (${productsFoundLength})`}
          </Text>
        </CTAButton>
        {productsFoundLength === 0 && (
          <Button
            type="button"
            onClick={clearForm}
            sx={{
              p: 0,
              m: 0,
              mt: '2sm',
              borderRadius: 0,
              border: 'none',
              height: 'fit-content',
              width: '100%',
              color: 'black',
              background: 'none',
              textTransform: 'none',
            }}
          >
            <Text variant="button" sx={{ textDecoration: 'underline' }}>
              Clear all filters
            </Text>
          </Button>
        )}
      </Box>
    </Flex>
  );
};

export default FilterMenu;
