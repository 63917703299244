import { forwardRef, useState } from 'react';
import { Label } from 'theme-ui';

// Render a custom radio or checkbox button
export type CustomInputButtonProps = {
  render: (isSelected: boolean) => JSX.Element;
  inputProps: React.InputHTMLAttributes<Omit<HTMLInputElement, 'type'>>;
  type: 'checkbox' | 'radio';
};

const CustomInputButton = forwardRef<HTMLInputElement, CustomInputButtonProps>(function CustomInputButton(
  { render, inputProps, type },
  ref
) {
  const [isSelected, setIsSelected] = useState(inputProps.checked ?? false);

  return (
    <Label
      sx={{
        cursor: 'pointer',
        maxWidth: 'fit-content',
        maxHeight: 'fit-content',
        position: 'relative',
        p: 0,
        m: 0,
      }}
    >
      {render(isSelected)}
      <input
        {...{ ...inputProps, type }}
        onChange={(event) => {
          if (inputProps.onChange) inputProps.onChange(event);
          setIsSelected(event.target.checked ?? false);
        }}
        ref={ref}
        sx={{
          position: 'absolute',
          height: '0',
          width: '0',
          visibility: 'hidden',
        }}
      ></input>
    </Label>
  );
});
export default CustomInputButton;
