import { Text } from 'theme-ui';

export const TextCurrentlySelected = ({ currentValue }: { currentValue?: string }) => {
  return (
    <Text
      variant="paragraphSmall"
      sx={{ lineHeight: '100%', overflow: 'hidden', maxWidth: '100%', textOverflow: 'ellipsis' }}
    >
      {currentValue}
    </Text>
  );
};
