import { Text } from 'theme-ui';
import CloseV2Small from '~/assets/images/icons/closeV2-small.svg';
import BaseButton from '../../BaseButton';

type FilterTagButtonProps = {
  label: string;
  handleClick?: () => void;
};

const FilterTagButton = ({ label, handleClick }: FilterTagButtonProps) => {
  return (
    <BaseButton
      sx={{
        ':hover': {
          backgroundColor: 'cloud',
        },
        p: '3xs',
        border: '1px solid',
        borderColor: 'UIGray1',
        backgroundColor: 'white',
        borderRadius: '2xs',
        cursor: 'pointer',
      }}
      onClick={handleClick}
    >
      <Text variant="button" sx={{ textTransform: 'capitalize', mr: '1rem' }}>
        {label}
      </Text>
      <span>
        <CloseV2Small />
      </span>
    </BaseButton>
  );
};

export default FilterTagButton;
