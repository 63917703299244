import { FilterObject } from '~/types/filter';
import { FilteredKeys, useShopFilter } from '..';
import BaseButton from '../../BaseButton';

import FilterIcon from '~/assets/images/icons/filterIcon.svg';
import SortIcon from '~/assets/images/icons/sortIcon.svg';
import { Flex, Text, Theme, ThemeUIStyleObject } from 'theme-ui';
import { openModal } from '../FilterMenu';
import FilterTagList from '../FilterList';
import { alpha } from '@theme-ui/color';

const FilterBar = () => {
  const {
    state: { saved },
  } = useShopFilter();
  const filter = saved.filter;
  const filterLength = Object.keys(filter).reduce((acc, key) => {
    const keyProp = key as FilteredKeys;
    const filterObject = filter[keyProp];
    if (keyProp === 'bundlesOnly' && filterObject === true) acc += 1;
    else if (keyProp === 'gender' && (filterObject as FilterObject).slug !== 'all') acc += 1;
    else if (Array.isArray(filterObject)) {
      acc += filterObject.length;
    }
    return acc;
  }, 0);

  const productCount = saved.filteredProducts.length;

  // Get the opposite sort order to order by
  // const newSortOrder = {
  //   by:
  //     saved.sort.by.value === SortKeys.CUSTOM // If sorting by custom value, do not change the sort by value, only order
  //       ? saved.sort.by
  //       : (availableSortOptions.find(
  //           (sortOption) => sortOption.by.value === saved.sort.by.value && sortOption.order !== saved.sort.order
  //         )?.by as SortObject),
  //   order: saved.sort.order === SortDirection.ASC ? SortDirection.DESC : SortDirection.ASC,
  // };

  const SortButton = (
    <BaseButton
      onClick={openModal}
      sx={{ height: '100%', p: 0, gap: '3xs', background: 'transparent', color: 'black' }}
    >
      <span>
        <SortIcon />
      </span>
      <Text variant="buttonLight">{saved.sort.by.title}</Text>
    </BaseButton>
  );

  const FilterButton = (
    <BaseButton
      onClick={openModal}
      sx={{
        height: '100%',
        p: 0,
        gap: '3xs',
        background: 'transparent',
        color: 'black',
      }}
    >
      <span>
        <FilterIcon />
      </span>

      <Text variant="buttonLight">Filter</Text>
      {filterLength > 0 && (
        <Flex
          sx={{
            borderRadius: 'full',
            backgroundColor: 'black',
            color: 'white',
            width: '1.4rem',
            height: '1.4rem',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Text variant="buttonLight" sx={{ fontSize: '1rem' }}>
            {filterLength}
          </Text>
        </Flex>
      )}
    </BaseButton>
  );

  const ItemCounter = (
    <Text variant="buttonLight">{productCount === 1 ? `${productCount} item` : `${productCount} items`}</Text>
  );

  const getSharedSx = (t: Theme): ThemeUIStyleObject => ({
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: 'cloud',
    width: '100%',
    position: 'relative',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: [alpha('backgroundGrey', 0.6)(t), null, alpha('backgroundGrey', 0.8)(t)],
    backdropFilter: 'saturate(180%) blur(20px)',
  });

  return (
    <div>
      {/* Mobile */}
      <Flex
        sx={(t) => ({
          ...getSharedSx(t),
          display: ['flex', 'none'],
          height: '4.8rem',
          px: '3md',
        })}
      >
        {FilterButton}
        {ItemCounter}
        {SortButton}
      </Flex>
      {/* Desktop */}
      <Flex
        sx={(t) => ({
          ...getSharedSx(t),
          display: ['none', 'flex'],
          height: 'fit-content',
          minHeight: '6.4rem',
          px: 'lg',
        })}
      >
        <Flex sx={{ gap: '3md', alignItems: 'center', height: 'fit-content' }}>
          {ItemCounter}
          <div sx={{ py: '1rem' }}>
            <FilterTagList showRestFilter={false} />
          </div>
        </Flex>
        <Flex sx={{ gap: '3md', height: '100%' }}>
          {FilterButton}

          {SortButton}
        </Flex>
      </Flex>
    </div>
  );
};

export default FilterBar;
