import { ReactNode, useEffect, useState } from 'react';
import CTAButton from '../CTAButton';
import Checkmark from '~/assets/images/icons/checkmark.svg';
import CheckmarkEmpty from '~/assets/images/icons/checkmarkEmpty.svg';
import useMountedKey from '~/hooks/utils/use-mounted-key';

const ButtonWithCheckmark = ({
  isSelected,
  handleClick,
  children,
}: {
  isSelected: boolean;
  children: ReactNode;
  handleClick: () => void;
}) => {
  const mountedKey = useMountedKey();

  return (
    <CTAButton
      key={mountedKey}
      variant="Outline"
      baseColor="black"
      type="button"
      forwardSx={{
        '--check-color': 'white',
        '.checkmark-icon': {
          color: 'var(--check-color)',
          transition: 'all 0.3s ease-in-out',
        },
        minWidth: 'fit-content',
        borderRadius: 'lg',
        display: 'flex',
        gap: '3xs',
        px: 'xs',
        py: '3xs',
        ':hover': {
          '--check-color': 'black',
        },
      }}
      onClick={handleClick}
    >
      <div
        sx={{
          width: '1.4rem',
        }}
      >
        {isSelected ? <Checkmark /> : <CheckmarkEmpty />}
      </div>
      {children}
    </CTAButton>
  );
};

export default ButtonWithCheckmark;
