import { TransformedProduct } from '~/types/filter';

const useProductPrice = (product: TransformedProduct) => {
  if (!product.variants) return null;

  const isPriceSameAcrossVariants = product.variants.every((v) => v?.price === product.filterData.price);

  if (isPriceSameAcrossVariants) return `$${product.filterData.price}`;

  const lowestPrice = Math.min(...product.variants.map((v) => v?.price ?? 0));
  const highestPrice = Math.max(...product.variants?.map((v) => v?.price ?? 0));

  return `$${lowestPrice} - $${highestPrice}`;
};

export default useProductPrice;
