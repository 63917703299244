import { useEffect, useState } from 'react';
import { TransformedBundleOrProductOrKit } from '~/types/filter';
import { isTransformedProduct } from '~/types/utils';

export const useShopAllProductCardSize = (productList: TransformedBundleOrProductOrKit[]) => {
  const [productListWithForcedCardSize, setProductListWithForcedCardSize] = useState(productList);

  useEffect(() => {
    const newProductList = forceCardSizeIfOrphaned(productList);
    setProductListWithForcedCardSize(newProductList);
  }, [productList.length, productList]);

  return productListWithForcedCardSize;
};

const forceCardSizeIfOrphaned = (productList: TransformedBundleOrProductOrKit[]) => {
  let lastLargeCardIndex: number | null = null; // to keep track of the last index that had a large card
  const newFilteredProducts = productList.map((product, index) => {
    const isBundle = (prod: TransformedBundleOrProductOrKit): boolean =>
      prod?.__typename === 'ContentfulKit' || prod?.__typename === 'ContentfulBundle';

    const isFeatured = (prod: TransformedBundleOrProductOrKit): boolean =>
      isTransformedProduct(prod) && Boolean(prod?.shopAllFeatured);

    const isLargeCard = (prod: TransformedBundleOrProductOrKit): boolean => isBundle(prod) || isFeatured(prod);

    if (isLargeCard(product)) {
      // if the current product is a large card, update the lastLargeCardIndex
      lastLargeCardIndex = index;
    }

    return product;
  });
  return newFilteredProducts;
};
