import { forwardRef } from 'react';
import { ButtonProps } from 'theme-ui';

const BaseButton = forwardRef<HTMLButtonElement, ButtonProps>(function BaseButton({ sx, ...rest }, ref) {
  return (
    <button
      ref={ref}
      sx={{
        appearance: 'none',
        WebkitAppearance: 'none',
        color: 'inherit',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: 'xs',
        cursor: 'pointer',
        border: 'none',
        borderRadius: '2xs',
        ':active': {
          outline: '1px solid black',
          outlineOffset: '5px',
        },
        transition: '.5s ease',
        ...sx,
      }}
      {...rest}
    />
  );
});
export default BaseButton;
