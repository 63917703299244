import { Box, Flex, Text } from 'theme-ui';
import { BundleAndSaveButton } from '../FilterPageButton';

const BundleAndSaveSection = () => (
  <Box sx={{ py: ['2sm', '3xs'], px: ['3md', '3xs'], backgroundColor: 'olive.light4' }}>
    <Flex sx={{ gap: ['1rem', '2md'], justifyContent: 'center', alignItems: 'center', p: ['1rem 0', '3xs'] }}>
      <Text variant="buttonLight" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', fontSize: ['1.6rem', '1.4rem'] }}>
        Show me your most valuable deals
      </Text>
      <BundleAndSaveButton />
    </Flex>
  </Box>
);

export default BundleAndSaveSection;
