import { useLayoutEffect, useRef } from 'react';

function useResizeObserver<T extends HTMLElement>(callback: (entry: ResizeObserverEntry) => void): React.RefObject<T> {
  const ref = useRef<T>(null);

  useLayoutEffect(() => {
    const element = ref.current;
    if (!element) return;

    const observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        callback(entry);
      }
    });

    observer.observe(element);

    return () => {
      observer.unobserve(element);
    };
  }, [callback]);

  return ref;
}

export default useResizeObserver;
