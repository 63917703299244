import React, { FC, useCallback, useLayoutEffect, useState } from 'react';
import { Flex, SxProp } from 'theme-ui';
import { TransformedProduct } from '~/types/filter';
import filterDuplicates from '~/utils/filter-duplicates';
import ProductLink from '../Product/Link';
import useResizeObserver from '~/hooks/components/use-resize-observer';

type Props = {
  product: TransformedProduct | Queries.ProductCardFragmentFragment;
  handleColorSwatchHover: (optionValue: Queries.OptionValuesFragmentFragment) => void;
  forwardSx?: SxProp['sx'];
};

type OptionValue = Queries.OptionValuesFragmentFragment;

const ShopAllColorSwatch: FC<Props> = ({ product, forwardSx, handleColorSwatchHover }) => {
  const [numberOfSwatchDots, setNumberOfSwatchDots] = useState(4);

  const calculateNumberOfSwatchDots = (containerWidth: number) => {
    const dotSize = 16;
    const internalGap = 12;
    return Math.floor(containerWidth / (dotSize + internalGap)) - 1;
  };

  const onResize = useCallback((entry: ResizeObserverEntry) => {
    const containerWidth = entry.contentRect.width;
    const dots = calculateNumberOfSwatchDots(containerWidth);
    setNumberOfSwatchDots(dots);
  }, []);

  const containerRef = useResizeObserver<HTMLDivElement>(onResize);

  useLayoutEffect(() => {
    if (!containerRef.current) return;
    const containerWidth = containerRef.current.getBoundingClientRect().width;
    const dots = calculateNumberOfSwatchDots(containerWidth);
    setNumberOfSwatchDots(dots);
  }, [containerRef]);

  if (!product?.variants?.length) return null;
  const allOptionValues = product.variants.map((variant) => variant?.optionValues).flat();

  const availableOptionValues: OptionValue[] = filterDuplicates(allOptionValues, 'slug');

  const allColors = availableOptionValues?.filter(
    ({ option_type = [] }) => option_type?.length && option_type[0]?.slug === 'color'
  );

  return (
    <Flex ref={containerRef} sx={{ ...forwardSx }} data-testid="color-swatch">
      {allColors.map((ov, index) => {
        if (index > numberOfSwatchDots - 1) return null;
        return (
          <ProductLink
            collectionSlug={product.collection?.[0]?.slug}
            productSlug={product.slug}
            productName={product.name}
            locationDetail={'Shop All'}
            key={ov.id}
            qs={`color=${ov.presentation}`}
            onMouseEnter={() => handleColorSwatchHover(ov)}
            onClick={(event: MouseEvent) => {
              // If mobile (hover is not available), don't follow the link.
              // Just set image to the correct color and qs to the correct color
              if (window.matchMedia('(hover: none)').matches) {
                event.preventDefault();
                event.stopPropagation();
                handleColorSwatchHover(ov);
              } else {
                return null;
              }
            }}
            sx={{
              position: 'relative',
              cursor: 'pointer',
              aspectRatio: '1',
              minHeight: 'var(--dots)',
              minWidth: 'var(--dots)',
              backgroundColor: ov.alternateRendering || 'black',
              border: '1px solid transparent',
              borderColor: ov.alternateRendering === '#E1E1E1' && 'darkCloud',
              borderRadius: '50%',
              overflow: 'hidden',
              transition: 'all 0.2s ease-in-out',
              '&:active, &:hover': {
                outline: '1px solid black',
                outlineOffset: '2px',
              },
              '::before': {
                content: '""',
                position: 'absolute',
                inset: 0,
                backgroundColor: ov.alternateRendering2,
                clipPath: 'polygon(100% 0, 0% 100%, 100% 100%)',
              },
            }}
          >
            <div />
          </ProductLink>
        );
      })}
      {allColors.length > numberOfSwatchDots && (
        <ProductLink
          collectionSlug={product.collection?.[0]?.slug}
          productSlug={product.slug}
          productName={product.name}
          locationDetail={'Shop All'}
        >
          <Flex
            sx={{
              aspectRatio: '1',
              minHeight: 'var(--dots)',
              minWidth: 'var(--dots)',
              border: '1px solid transparent',
              borderColor: 'darkCloud',
              borderRadius: '50%',
              fontSize: '1rem',
              lineHeight: 1.15,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <span>+{allColors.length - numberOfSwatchDots}</span>
          </Flex>
        </ProductLink>
      )}
    </Flex>
  );
};

export default ShopAllColorSwatch;
