import React from 'react';
import { Grid, Text } from 'theme-ui';
import CTAButton from '../Generic/CTAButton';
import { useTheme } from '~/gatsby-plugin-theme-ui';
import { useShopFilter } from '../Generic/Filter';

const NoProducts = () => {
  const { theme } = useTheme();
  const {
    methods: { clear },
  } = useShopFilter();
  return (
    <Grid
      sx={{
        p: theme.space['3md'],
        gap: theme.space['2md'],
        background: theme.colors.fairPink,
        gridColumn: ['span 2', 'auto'],
      }}
    >
      <Text variant="paragraphSmall">There are no products matching, try clearing a filter or two</Text>
      <CTAButton baseColor="Black" variant="Outlined" onClick={() => clear()}>
        Clear all filters
      </CTAButton>
    </Grid>
  );
};

export default NoProducts;
