import { forwardRef } from 'react';
import { Checkbox, CheckboxProps, Label, LabelProps, Text } from 'theme-ui';

export interface CheckboxButtonProps {
  checkboxProps: CheckboxProps;
  labelProps?: Omit<LabelProps, 'children'>;
  labelString: string;
  variant?: 'right' | 'left';
}

const CheckboxButton = forwardRef<HTMLInputElement, CheckboxButtonProps>(function CheckboxButton(
  props: CheckboxButtonProps,
  ref
) {
  const direction = props.variant ?? 'left';
  return (
    <Label
      {...(props.labelProps ?? {})}
      sx={{
        ...(direction === 'right' && {
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between',
        }),
        cursor: 'pointer',
        alignItems: 'center',
      }}
    >
      <Checkbox
        ref={ref}
        {...props.checkboxProps}
        sx={{
          size: '2.2rem',
          ml: '-0.2rem',
          ...(direction === 'right' && {
            size: 'auto',
            m: '0',
            mr: '-0.2rem',
          }),
        }}
      />
      <Text
        variant={'label'}
        sx={{
          ...(direction === 'right' && {
            fontSize: '1.4rem',
          }),
        }}
      >
        {props.labelString}
      </Text>
    </Label>
  );
});

export default CheckboxButton;
