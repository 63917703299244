import { Text } from 'theme-ui';
import { useShopFilter } from '..';
import ButtonWithCheckmark from '../../ButtonWithCheckmark';

const BundleAndSaveButton = () => {
  const {
    methods,
    state: { saved },
  } = useShopFilter();
  const filter = saved.filter;

  return (
    <ButtonWithCheckmark
      isSelected={filter.bundlesOnly}
      handleClick={() => {
        if (!filter.bundlesOnly) {
          methods.filter({ property: 'bundlesOnly', payload: true });
        } else {
          methods.remove({ property: 'bundlesOnly' });
        }
        if (filter.excludeBundles) methods.remove({ property: 'excludeBundles' });

        methods.save();
      }}
    >
      <Text variant="buttonSmall">Bundle and save</Text>
    </ButtonWithCheckmark>
  );
};

export { BundleAndSaveButton };
