import { useRef } from 'react';
import { Box, Text } from 'theme-ui';
import newTheme from '~/gatsby-plugin-theme-ui';
import useResizeObserver from '~/hooks/components/use-resize-observer';
import useMountedKey from '~/hooks/utils/use-mounted-key';

type TabButtonProp = {
  value: string | null;
  label: string | null;
};

const nonActiveColor = newTheme?.colors?.['UIGray2'] || 'black';
const AnimatedSlidingToggle = ({
  currentActiveButtonIndex = 0,
  handleClick,
  tabs,
  pillFitContent = true,
}: {
  currentActiveButtonIndex: number;
  handleClick: (value: string) => void;
  tabs: TabButtonProp[];
  pillFitContent?: boolean;
}) => {
  const tabsRef = useRef<(HTMLButtonElement | null)[]>([]); // Get all the buttons in here

  const mountedKey = useMountedKey();

  const onResize = () => {
    // Adjust black background position
    const activeButton = tabsRef.current[currentActiveButtonIndex];
    const background = sliderRef.current;
    if (!background || !activeButton) return;

    const activeButtonRect = activeButton.getBoundingClientRect();
    const activeButtonLeft = activeButton.offsetLeft;
    const activeButtonWidth = activeButtonRect.width;
    background.style.setProperty('--width', `${activeButtonWidth}px`);
    background.style.setProperty('--height', `${activeButtonRect.height}px`);
    background.style.setProperty('--left', `${activeButtonLeft}px`);
    background.style.setProperty('--scale', 'scale(1,1)');
  };

  const sliderRef = useResizeObserver<HTMLDivElement>(onResize);

  return (
    <Box
      ref={sliderRef}
      sx={{
        '--height': '100%',
        '--opacity': 0,
        '--scale': 'scale(0,1)',
        display: pillFitContent ? 'flex' : 'grid',
        gridTemplateColumns: pillFitContent ? null : `repeat(${tabs.length}, 1fr)`,
        borderRadius: 'lg',
        overflow: 'hidden',
        width: pillFitContent ? 'fit-content' : 'max-content',
        minWidth: 'fit-content', // Safari is weird
        position: 'relative',
        isolation: 'isolate',
        blockSize: 'fit-content',
        // before is the pill
        ':before': {
          content: "''",
          top: 0,
          borderRadius: 'lg',
          transform: 'translateZ(0) var(--scale)', // Safari is weird
          position: 'absolute',
          transition: '0.5s cubic-bezier(.1,.59,.04,1)',
          width: 'calc(var(--width) + 2px)',
          height: 'var(--height)',
          left: 'var(--left)',
          backgroundColor: 'black',
        },
        // after is the border, so it doesn't create a very thin gap between the pill and the border
        ':after': {
          content: "''",
          inset: 0,
          position: 'absolute',
          borderRadius: 'lg',
          border: '1px solid black',
        },
      }}
    >
      {tabs.map((tab, index) => {
        const isSelected = index === currentActiveButtonIndex;
        return (
          <button
            role="tab"
            key={mountedKey + tab.value}
            aria-selected={isSelected ? 'true' : 'false'}
            onClick={() => {
              handleClick(tab?.value ?? '');
            }}
            onTouchStart={(event) => {
              event.preventDefault();
              handleClick(tab?.value ?? '');
            }}
            ref={(el) => (tabsRef.current[index] = el)}
            sx={{
              color: isSelected ? 'white' : nonActiveColor,
              py: '2xs',
              px: '3md',
              background: 'transparent',
              zIndex: 2,
              borderRadius: 'lg',
              transition: '0.1s cubic-bezier(.1,.59,.04,1)',
              transitionDelay: '.1s',
              cursor: 'pointer',
              border: 'none',
              width: pillFitContent ? 'fit-content' : '100%',
              WebkitTapHighlightColor: 'transparent',
              ':active': {
                appearance: 'none',
              },
            }}
          >
            <Text variant="button">{tab.label}</Text>
          </button>
        );
      })}
    </Box>
  );
};

export default AnimatedSlidingToggle;
