import { PropsWithChildren, ReactNode, useState } from 'react';
import { Flex, Text } from 'theme-ui';
import Plus from '~/assets/images/icons/plusV2.svg';
import Minus from '~/assets/images/icons/minusV2.svg';

type DetailSectionProps = {
  title: string;
  showSelectionOnOpen?: boolean;
  CurrentSelectedRenderComponent?: ReactNode;
};

const DetailSection = ({
  children,
  showSelectionOnOpen = true,
  title,
  CurrentSelectedRenderComponent = null,
}: PropsWithChildren<DetailSectionProps>) => {
  const [isOpen, setIsOpen] = useState(false);

  // hide if not open, unless showSelectionOnOpen is true
  const showSelection = (!showSelectionOnOpen && !isOpen) || showSelectionOnOpen;

  return (
    <details sx={{ borderBottom: '1px solid', borderColor: 'cardGrey' }} open={isOpen}>
      <summary
        sx={{
          '::-webkit-details-marker': { display: 'none' },
          listStyle: 'none',
          py: '3md',
          m: 0,
          cursor: 'pointer',
        }}
        onClick={(event) => {
          event.preventDefault();
          setIsOpen(!isOpen);
        }}
      >
        <Flex sx={{ justifyContent: 'space-between', gap: '2xs', alignItems: 'center' }}>
          <Text sx={{ flexShrink: 0 }} variant="button">
            {title}
          </Text>
          <Flex sx={{ alignItems: 'center' }}>
            {!!CurrentSelectedRenderComponent && showSelection && CurrentSelectedRenderComponent}
            <Flex
              sx={{
                flexShrink: 0,
                p: 0,
                m: 0,
                ml: '2xs',
                width: '1.2rem',
                height: '1.2rem',
                cursor: 'pointer',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {isOpen ? <Minus /> : <Plus />}
            </Flex>
          </Flex>
        </Flex>
      </summary>
      <div sx={{ py: '3xs', mb: '3md' }}>{children}</div>
    </details>
  );
};
export default DetailSection;
