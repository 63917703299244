export const ColorComponent = ({ size, color }: { size: 'xs' | 'sm'; color: string }) => {
  if (size === 'xs')
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none" sx={{ color }}>
        <rect y="0.5" width="16" height="16" rx="8" fill="currentColor" />
      </svg>
    );

  if (size === 'sm')
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" sx={{ color }}>
        <rect width="24" height="24" rx="12" fill="currentColor" />
      </svg>
    );

  return null;
};
export default ColorComponent;
