import { ReactNode } from 'react';
import { ThemeUIStyleObject } from 'theme-ui';

const StaticFilterSection = ({ children, forwardSx }: { children: ReactNode; forwardSx?: ThemeUIStyleObject }) => {
  return (
    <div
      sx={{
        py: '3md',
        borderBottom: '1px solid',
        borderColor: 'cardGrey',
        ...forwardSx,
      }}
    >
      {children}
    </div>
  );
};

export default StaticFilterSection;
