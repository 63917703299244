import { useEffect, useState } from 'react';

const useMountedKey = () => {
  // https://github.com/gatsbyjs/gatsby/discussions/17914#discussion-37858
  const [mountedKey, setMountedKey] = useState<'server' | 'client'>('server');

  useEffect(() => {
    setMountedKey('client');
  }, []);

  return mountedKey;
};

export default useMountedKey;
