import { graphql, useStaticQuery } from 'gatsby';

const useCustomSortTitle = (): string => {
  const data = useStaticQuery(graphql`
    query CustomSortTitle {
      catalog: allContentfulCatalog(filter: { slug: { eq: "catalog" } }) {
        edges {
          node {
            customSortTitle
          }
        }
      }
    }
  `);
  const customSortTitle: string = data?.catalog?.edges?.[0]?.node?.customSortTitle ?? 'Initial';

  return customSortTitle;
};

export default useCustomSortTitle;
